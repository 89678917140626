<template>
  <div style="padding: 10px 0;margin-right:20px">
    <el-row>
      <el-col :span="25" :offset="0">
        <div style="padding: 10px 0;  border-bottom: 1px dashed #ccc;margin-top: 1px">
          <span style="font-size: 30px;">{{ article.title }}</span>
        </div>
        <div style="padding: 10px 0 30px 0">
          <b>{{ article.author }}</b>
          <span style="margin-left: 10px; color: #666; font-size: 12px">{{ article.time }}</span>
          <span style="margin-left: 10px; color: #666; font-size: 12px">阅读数: {{ article.readCount }}</span>
        </div>
        <div style="padding: 10px 0; color: #333; font-size: 14px; line-height: 25px;font-family: 仿宋" v-html="article.content"></div>

        <div style="border-top: 1px solid #ccc; margin-top: 20px; padding: 20px 0; text-align: center">
          <el-button type="danger" size="medium" @click="handlePraise">点赞({{article.praise}})</el-button>
        </div>

        <div style="padding: 10px 0; margin-bottom: 30px">
          <el-input type="textarea" v-model="entity.content"></el-input>
          <div style="text-align: right; padding: 5px 0">
            <el-button @click="save">评论</el-button>
          </div>
        </div>

        <div style="display: flex; padding: 20px" v-for="item in messages">
          <div style="text-align: left; flex: 1">
            <el-image :src="item.avatar" style="width: 60px; height: 60px; border-radius: 50%"></el-image>
          </div>
          <div style="padding: 0 10px; flex: 5;text-align: left;">
            <div><b style="font-size: 14px;text-align: left;">{{ item.username }}</b></div>
            <div style="padding: 10px 0; color: #888;text-align: left;">
              {{ item.content }}
              <el-button type="text" size="mini" @click="del(item.id)" v-if="item.username === user.username">删除</el-button>
            </div>
            <div style="background-color: #eee; padding: 10px;text-align: left;" v-if="item.parentMessage">{{ item.parentMessage.username }}：{{ item.parentMessage.content }}</div>
            <div style="color: #888; font-size: 12px;text-align: left;">
              <span>{{ item.time  }}</span>
              <el-button type="text" style="margin-left: 20px" @click="reReply(item.id)">回复</el-button>
            </div>
          </div>
        </div>

        <el-dialog title="回复信息" v-model="dialogFormVisible" width="30%">
          <el-form :model="entity" label-width="80px">
            <el-form-item label="内容">
              <el-input v-model="entity.reply" autocomplete="off" type="textarea" :rows="3"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="reply">确 定</el-button>
          </template>
        </el-dialog>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import request from "@/utils/request";
let id;

export default {
  name: "Detail",
  data() {
    return {
      article: {},
      dialogFormVisible: false,
      entity: {},
      messages: [],
      user: {},
      flag: false
    }
  },
  created() {
    id = location.search.split("=")[1]
    this.user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {};
    this.load()
    this.loadMessage()
    // 阅读数 +1

  },
  methods: {
    load() {
      request.get("/article/" + id).then(res => {
        this.article = res.data

        this.article.readCount += 1
        request.put("/article/", this.article)
      })
    },
    loadMessage() {
      request.get("/message/foreign/" + id).then(res => {
        this.messages = res.data;
      })
    },
    cancel() {
      this.dialogFormVisible = false;
      this.entity = {};
    },
    reReply(id) {
      console.log("reply to message Id",id)
      this.dialogFormVisible = true;
      this.entity.parentId = id;
    },
    reply() {
      this.entity.content = this.entity.reply;
      this.save();
    },

    save() {
      if (!this.user.username) {
        this.$message({
          message: "请登录",
          type: "warning"
        });
        return;
      }
      if (!this.entity.content) {
        this.$message({
          message: "请填写内容",
          type: "warning"
        });
        return;
      }
      this.entity.username = this.user.username
      this.entity.foreignId = id
      request.post("/message", this.entity).then(res => {
        if (res.code === '0') {
          this.$message({
            message: "评论成功",
            type: "success"
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error,请登录",
          });
          this.$route.push("/login");
        }
        this.entity = {}
        this.loadMessage();
        this.dialogFormVisible = false;
      })
    },
    del(id) {
      request.delete("/message/" + id).then(res => {
        this.$message({
          message: "删除成功",
          type: "success"
        });
        this.loadMessage()
      })
    },
    handlePraise() {
      if(this.flag) {
        this.$message({
          message: "你已经点过赞了",
          type: "warning"
        });
      } else {
        this.flag = true
        this.article.praise += 1
        request.put("/article/", this.article).then(res => {
          this.$message({
            message: "点赞成功",
            type: "success"
          });
        })
      }

    }
  }
}
</script>

<style scoped>
.title:hover {
  color: dodgerblue;
}
</style>
